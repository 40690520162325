//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'IntroIconBadge',
  props: {
    img: { type: String, default: '' },
    info: { type: Array, default: () => [] },
  },
}
