//
//
//
//
//
//
//

export default {
  name: 'ScrollHint',
  props: {
    text: { type: String, default: 'SCROLL' },
  },
}
