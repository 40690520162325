import { render, staticRenderFns } from "./IndexTopSection.vue?vue&type=template&id=91ee1dd2&scoped=true&"
import script from "./IndexTopSection.vue?vue&type=script&lang=js&"
export * from "./IndexTopSection.vue?vue&type=script&lang=js&"
import style0 from "./IndexTopSection.vue?vue&type=style&index=0&id=91ee1dd2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "91ee1dd2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Lottie: require('/var/www/ebatte/offical-site/components/utils/Lottie.vue').default,FloatPoints1: require('/var/www/ebatte/offical-site/components/index/FloatPoints1.vue').default,FloatPoints2: require('/var/www/ebatte/offical-site/components/index/FloatPoints2.vue').default})
