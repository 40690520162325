import { render, staticRenderFns } from "./index.vue?vue&type=template&id=68866dde&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./index.vue?vue&type=style&index=1&id=68866dde&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68866dde",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageLoading: require('/var/www/ebatte/offical-site/components/utils/PageLoading.vue').default,NavBar: require('/var/www/ebatte/offical-site/components/nav-bar/NavBar.vue').default,Cookies: require('/var/www/ebatte/offical-site/components/utils/Cookies.vue').default,IndexTopSection: require('/var/www/ebatte/offical-site/components/index/IndexTopSection.vue').default,IndexTopSectionMobile: require('/var/www/ebatte/offical-site/components/index/IndexTopSectionMobile.vue').default,IndexRecycleEconomy: require('/var/www/ebatte/offical-site/components/index/IndexRecycleEconomy.vue').default,IndexBatteryTechnology: require('/var/www/ebatte/offical-site/components/index/IndexBatteryTechnology.vue').default,IndexDownload: require('/var/www/ebatte/offical-site/components/index/IndexDownload.vue').default,Lottie: require('/var/www/ebatte/offical-site/components/utils/Lottie.vue').default,Footer: require('/var/www/ebatte/offical-site/components/Footer.vue').default,FloatDetails: require('/var/www/ebatte/offical-site/components/index/FloatDetails.vue').default})
