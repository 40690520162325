import { render, staticRenderFns } from "./DetailsContent.vue?vue&type=template&id=a7d22258&scoped=true&"
import script from "./DetailsContent.vue?vue&type=script&lang=js&"
export * from "./DetailsContent.vue?vue&type=script&lang=js&"
import style0 from "./DetailsContent.vue?vue&type=style&index=0&id=a7d22258&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a7d22258",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {P1ECommerce: require('/var/www/ebatte/offical-site/components/index/pages/P1ECommerce.vue').default,P2RentalService: require('/var/www/ebatte/offical-site/components/index/pages/P2RentalService.vue').default,P3UserInterface: require('/var/www/ebatte/offical-site/components/index/pages/P3UserInterface.vue').default,P4WaterbasedBattery: require('/var/www/ebatte/offical-site/components/index/pages/P4WaterbasedBattery.vue').default,P5RecycleReward: require('/var/www/ebatte/offical-site/components/index/pages/P5RecycleReward.vue').default,P6Content: require('/var/www/ebatte/offical-site/components/index/pages/P6Content.vue').default,P7Warranty: require('/var/www/ebatte/offical-site/components/index/pages/P7Warranty.vue').default,P8AiResearchAndAnalysis: require('/var/www/ebatte/offical-site/components/index/pages/P8AiResearchAndAnalysis.vue').default,P9RecycleService: require('/var/www/ebatte/offical-site/components/index/pages/P9RecycleService.vue').default,P10ProductDesign: require('/var/www/ebatte/offical-site/components/index/pages/P10ProductDesign.vue').default})
