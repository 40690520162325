//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Subscribe from '@/components/index/Subscribe'
import FooterLinks from '@/components/index/FooterLinks'
import FooterPolicy from '@/components/index/FooterPolicy'
export default {
  name: 'Footer',
  components: { FooterPolicy, FooterLinks, Subscribe },
}
