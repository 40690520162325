import Vue from 'vue'

export default Vue.mixin({
  data() {
    return {
      timeouts: [],
      animation: {},
      animationLoadCount: 0,
      totalAnimationCount: 0,
      animationLoadCallback: () => {},
      animationFinishCallback: () => {},
    }
  },
  methods: {
    handleAnimation(name, anim) {
      // @ts-ignore
      if (!this.animation) {
        // @ts-ignore
        this.animation = {}
      }
      // @ts-ignore
      this.animation[name] = anim
      // @ts-ignore
      if (this.animationLoadCallback) {
        // @ts-ignore
        this.animationLoadCallback(name, anim)
      }
      // @ts-ignore
      this.animationLoadCount += 1
      // @ts-ignore
      if (this.animationLoadCount >= this.totalAnimationCount) {
        // load finished
        // @ts-ignore
        if (this.animationFinishCallback) {
          // @ts-ignore
          this.animationFinishCallback()
        }
      }
    },

    clearTimeout() {
      // @ts-ignore
      if (this.timeouts) {
        // @ts-ignore
        this.timeouts.forEach((t) => clearTimeout(t))
      }
      // @ts-ignore
      this.timeouts = []
    },
    addTimeOut(...timeouts) {
      // @ts-ignore
      if (!this.timeouts) {
        // @ts-ignore
        this.timeouts = []
      }
      // @ts-ignore
      this.timeouts.push(...timeouts)
    },
    setTotalNum(num) {
      // @ts-ignore
      this.totalAnimationCount = num
    },
  },
})
