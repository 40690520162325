import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _8a60d1ca = () => interopDefault(import('../pages/about-us.vue' /* webpackChunkName: "pages/about-us" */))
const _18c6210c = () => interopDefault(import('../pages/article/index.vue' /* webpackChunkName: "pages/article/index" */))
const _481745e8 = () => interopDefault(import('../pages/contact-us.vue' /* webpackChunkName: "pages/contact-us" */))
const _26507662 = () => interopDefault(import('../pages/cookies.vue' /* webpackChunkName: "pages/cookies" */))
const _12948a74 = () => interopDefault(import('../pages/indexpage.vue' /* webpackChunkName: "pages/indexpage" */))
const _4a431436 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _43282242 = () => interopDefault(import('../pages/mission.vue' /* webpackChunkName: "pages/mission" */))
const _d2debf08 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _02e9d9ff = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _1bb49a7b = () => interopDefault(import('../pages/profile/order.vue' /* webpackChunkName: "pages/profile/order" */))
const _25f02669 = () => interopDefault(import('../pages/recycle/index.vue' /* webpackChunkName: "pages/recycle/index" */))
const _b3d4dbf0 = () => interopDefault(import('../pages/user.vue' /* webpackChunkName: "pages/user" */))
const _68b58e74 = () => interopDefault(import('../pages/user/forget-password.vue' /* webpackChunkName: "pages/user/forget-password" */))
const _5673d4bc = () => interopDefault(import('../pages/user/login.vue' /* webpackChunkName: "pages/user/login" */))
const _43021776 = () => interopDefault(import('../pages/user/retrieve-password.vue' /* webpackChunkName: "pages/user/retrieve-password" */))
const _25c3d5a4 = () => interopDefault(import('../pages/user/sign-up.vue' /* webpackChunkName: "pages/user/sign-up" */))
const _23f359f2 = () => interopDefault(import('../pages/user/sign-up-verify.vue' /* webpackChunkName: "pages/user/sign-up-verify" */))
const _56e3f08b = () => interopDefault(import('../pages/mobile/contact-us.vue' /* webpackChunkName: "pages/mobile/contact-us" */))
const _78d74df2 = () => interopDefault(import('../pages/pay/payment.vue' /* webpackChunkName: "pages/pay/payment" */))
const _2cc0f692 = () => interopDefault(import('../pages/pay/payment/alipay.vue' /* webpackChunkName: "pages/pay/payment/alipay" */))
const _4ffdf3bd = () => interopDefault(import('../pages/pay/payment/card.vue' /* webpackChunkName: "pages/pay/payment/card" */))
const _5857fce0 = () => interopDefault(import('../pages/pay/payment/paypal.vue' /* webpackChunkName: "pages/pay/payment/paypal" */))
const _795e50da = () => interopDefault(import('../pages/pay/payment/wechat.vue' /* webpackChunkName: "pages/pay/payment/wechat" */))
const _aadc911c = () => interopDefault(import('../pages/pay/shipping.vue' /* webpackChunkName: "pages/pay/shipping" */))
const _924bb5cc = () => interopDefault(import('../pages/pay/shipping/address/index.vue' /* webpackChunkName: "pages/pay/shipping/address/index" */))
const _85fab3fc = () => interopDefault(import('../pages/pay/shipping/address/_id.vue' /* webpackChunkName: "pages/pay/shipping/address/_id" */))
const _5ad71775 = () => interopDefault(import('../pages/recycle/locations.vue' /* webpackChunkName: "pages/recycle/locations" */))
const _34a79af5 = () => interopDefault(import('../pages/recycle/pickup.vue' /* webpackChunkName: "pages/recycle/pickup" */))
const _bfab92a8 = () => interopDefault(import('../pages/shop/cart.vue' /* webpackChunkName: "pages/shop/cart" */))
const _6f0b9ca8 = () => interopDefault(import('../pages/shop/categories.vue' /* webpackChunkName: "pages/shop/categories" */))
const _1928fcb2 = () => interopDefault(import('../pages/article/details/_id.vue' /* webpackChunkName: "pages/article/details/_id" */))
const _76f23835 = () => interopDefault(import('../pages/shop/category/_id.vue' /* webpackChunkName: "pages/shop/category/_id" */))
const _4183b111 = () => interopDefault(import('../pages/shop/details/_id.vue' /* webpackChunkName: "pages/shop/details/_id" */))
const _6cecbdd4 = () => interopDefault(import('../pages/article/_type/index.vue' /* webpackChunkName: "pages/article/_type/index" */))
const _35a0b65c = () => interopDefault(import('../pages/article/_type/tablet.vue' /* webpackChunkName: "pages/article/_type/tablet" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-us",
    component: _8a60d1ca,
    name: "about-us___EN"
  }, {
    path: "/article",
    component: _18c6210c,
    name: "article___EN"
  }, {
    path: "/contact-us",
    component: _481745e8,
    name: "contact-us___EN"
  }, {
    path: "/cookies",
    component: _26507662,
    name: "cookies___EN"
  }, {
    path: "/indexpage",
    component: _12948a74,
    name: "indexpage___EN"
  }, {
    path: "/KO",
    component: _4a431436,
    name: "index___KO"
  }, {
    path: "/mission",
    component: _43282242,
    name: "mission___EN"
  }, {
    path: "/profile",
    component: _d2debf08,
    children: [{
      path: "",
      component: _02e9d9ff,
      name: "profile___EN"
    }, {
      path: "order",
      component: _1bb49a7b,
      name: "profile-order___EN"
    }]
  }, {
    path: "/recycle",
    component: _25f02669,
    name: "recycle___EN"
  }, {
    path: "/SC",
    component: _4a431436,
    name: "index___SC"
  }, {
    path: "/user",
    component: _b3d4dbf0,
    name: "user___EN",
    children: [{
      path: "forget-password",
      component: _68b58e74,
      name: "user-forget-password___EN"
    }, {
      path: "login",
      component: _5673d4bc,
      name: "user-login___EN"
    }, {
      path: "retrieve-password",
      component: _43021776,
      name: "user-retrieve-password___EN"
    }, {
      path: "sign-up",
      component: _25c3d5a4,
      name: "user-sign-up___EN"
    }, {
      path: "sign-up-verify",
      component: _23f359f2,
      name: "user-sign-up-verify___EN"
    }]
  }, {
    path: "/ZH",
    component: _4a431436,
    name: "index___ZH"
  }, {
    path: "/KO/about-us",
    component: _8a60d1ca,
    name: "about-us___KO"
  }, {
    path: "/KO/article",
    component: _18c6210c,
    name: "article___KO"
  }, {
    path: "/KO/contact-us",
    component: _481745e8,
    name: "contact-us___KO"
  }, {
    path: "/KO/cookies",
    component: _26507662,
    name: "cookies___KO"
  }, {
    path: "/KO/indexpage",
    component: _12948a74,
    name: "indexpage___KO"
  }, {
    path: "/KO/mission",
    component: _43282242,
    name: "mission___KO"
  }, {
    path: "/KO/profile",
    component: _d2debf08,
    children: [{
      path: "",
      component: _02e9d9ff,
      name: "profile___KO"
    }, {
      path: "order",
      component: _1bb49a7b,
      name: "profile-order___KO"
    }]
  }, {
    path: "/KO/recycle",
    component: _25f02669,
    name: "recycle___KO"
  }, {
    path: "/KO/user",
    component: _b3d4dbf0,
    name: "user___KO",
    children: [{
      path: "forget-password",
      component: _68b58e74,
      name: "user-forget-password___KO"
    }, {
      path: "login",
      component: _5673d4bc,
      name: "user-login___KO"
    }, {
      path: "retrieve-password",
      component: _43021776,
      name: "user-retrieve-password___KO"
    }, {
      path: "sign-up",
      component: _25c3d5a4,
      name: "user-sign-up___KO"
    }, {
      path: "sign-up-verify",
      component: _23f359f2,
      name: "user-sign-up-verify___KO"
    }]
  }, {
    path: "/mobile/contact-us",
    component: _56e3f08b,
    name: "mobile-contact-us___EN"
  }, {
    path: "/pay/payment",
    component: _78d74df2,
    name: "pay-payment___EN",
    children: [{
      path: "alipay",
      component: _2cc0f692,
      name: "pay-payment-alipay___EN"
    }, {
      path: "card",
      component: _4ffdf3bd,
      name: "pay-payment-card___EN"
    }, {
      path: "paypal",
      component: _5857fce0,
      name: "pay-payment-paypal___EN"
    }, {
      path: "wechat",
      component: _795e50da,
      name: "pay-payment-wechat___EN"
    }]
  }, {
    path: "/pay/shipping",
    component: _aadc911c,
    name: "pay-shipping___EN",
    children: [{
      path: "address",
      component: _924bb5cc,
      name: "pay-shipping-address___EN"
    }, {
      path: "address/:id",
      component: _85fab3fc,
      name: "pay-shipping-address-id___EN"
    }]
  }, {
    path: "/recycle/locations",
    component: _5ad71775,
    name: "recycle-locations___EN"
  }, {
    path: "/recycle/pickup",
    component: _34a79af5,
    name: "recycle-pickup___EN"
  }, {
    path: "/SC/about-us",
    component: _8a60d1ca,
    name: "about-us___SC"
  }, {
    path: "/SC/article",
    component: _18c6210c,
    name: "article___SC"
  }, {
    path: "/SC/contact-us",
    component: _481745e8,
    name: "contact-us___SC"
  }, {
    path: "/SC/cookies",
    component: _26507662,
    name: "cookies___SC"
  }, {
    path: "/SC/indexpage",
    component: _12948a74,
    name: "indexpage___SC"
  }, {
    path: "/SC/mission",
    component: _43282242,
    name: "mission___SC"
  }, {
    path: "/SC/profile",
    component: _d2debf08,
    children: [{
      path: "",
      component: _02e9d9ff,
      name: "profile___SC"
    }, {
      path: "order",
      component: _1bb49a7b,
      name: "profile-order___SC"
    }]
  }, {
    path: "/SC/recycle",
    component: _25f02669,
    name: "recycle___SC"
  }, {
    path: "/SC/user",
    component: _b3d4dbf0,
    name: "user___SC",
    children: [{
      path: "forget-password",
      component: _68b58e74,
      name: "user-forget-password___SC"
    }, {
      path: "login",
      component: _5673d4bc,
      name: "user-login___SC"
    }, {
      path: "retrieve-password",
      component: _43021776,
      name: "user-retrieve-password___SC"
    }, {
      path: "sign-up",
      component: _25c3d5a4,
      name: "user-sign-up___SC"
    }, {
      path: "sign-up-verify",
      component: _23f359f2,
      name: "user-sign-up-verify___SC"
    }]
  }, {
    path: "/shop/cart",
    component: _bfab92a8,
    name: "shop-cart___EN"
  }, {
    path: "/shop/categories",
    component: _6f0b9ca8,
    name: "shop-categories___EN"
  }, {
    path: "/ZH/about-us",
    component: _8a60d1ca,
    name: "about-us___ZH"
  }, {
    path: "/ZH/article",
    component: _18c6210c,
    name: "article___ZH"
  }, {
    path: "/ZH/contact-us",
    component: _481745e8,
    name: "contact-us___ZH"
  }, {
    path: "/ZH/cookies",
    component: _26507662,
    name: "cookies___ZH"
  }, {
    path: "/ZH/indexpage",
    component: _12948a74,
    name: "indexpage___ZH"
  }, {
    path: "/ZH/mission",
    component: _43282242,
    name: "mission___ZH"
  }, {
    path: "/ZH/profile",
    component: _d2debf08,
    children: [{
      path: "",
      component: _02e9d9ff,
      name: "profile___ZH"
    }, {
      path: "order",
      component: _1bb49a7b,
      name: "profile-order___ZH"
    }]
  }, {
    path: "/ZH/recycle",
    component: _25f02669,
    name: "recycle___ZH"
  }, {
    path: "/ZH/user",
    component: _b3d4dbf0,
    name: "user___ZH",
    children: [{
      path: "forget-password",
      component: _68b58e74,
      name: "user-forget-password___ZH"
    }, {
      path: "login",
      component: _5673d4bc,
      name: "user-login___ZH"
    }, {
      path: "retrieve-password",
      component: _43021776,
      name: "user-retrieve-password___ZH"
    }, {
      path: "sign-up",
      component: _25c3d5a4,
      name: "user-sign-up___ZH"
    }, {
      path: "sign-up-verify",
      component: _23f359f2,
      name: "user-sign-up-verify___ZH"
    }]
  }, {
    path: "/KO/mobile/contact-us",
    component: _56e3f08b,
    name: "mobile-contact-us___KO"
  }, {
    path: "/KO/pay/payment",
    component: _78d74df2,
    name: "pay-payment___KO",
    children: [{
      path: "alipay",
      component: _2cc0f692,
      name: "pay-payment-alipay___KO"
    }, {
      path: "card",
      component: _4ffdf3bd,
      name: "pay-payment-card___KO"
    }, {
      path: "paypal",
      component: _5857fce0,
      name: "pay-payment-paypal___KO"
    }, {
      path: "wechat",
      component: _795e50da,
      name: "pay-payment-wechat___KO"
    }]
  }, {
    path: "/KO/pay/shipping",
    component: _aadc911c,
    name: "pay-shipping___KO",
    children: [{
      path: "address",
      component: _924bb5cc,
      name: "pay-shipping-address___KO"
    }, {
      path: "address/:id",
      component: _85fab3fc,
      name: "pay-shipping-address-id___KO"
    }]
  }, {
    path: "/KO/recycle/locations",
    component: _5ad71775,
    name: "recycle-locations___KO"
  }, {
    path: "/KO/recycle/pickup",
    component: _34a79af5,
    name: "recycle-pickup___KO"
  }, {
    path: "/KO/shop/cart",
    component: _bfab92a8,
    name: "shop-cart___KO"
  }, {
    path: "/KO/shop/categories",
    component: _6f0b9ca8,
    name: "shop-categories___KO"
  }, {
    path: "/SC/mobile/contact-us",
    component: _56e3f08b,
    name: "mobile-contact-us___SC"
  }, {
    path: "/SC/pay/payment",
    component: _78d74df2,
    name: "pay-payment___SC",
    children: [{
      path: "alipay",
      component: _2cc0f692,
      name: "pay-payment-alipay___SC"
    }, {
      path: "card",
      component: _4ffdf3bd,
      name: "pay-payment-card___SC"
    }, {
      path: "paypal",
      component: _5857fce0,
      name: "pay-payment-paypal___SC"
    }, {
      path: "wechat",
      component: _795e50da,
      name: "pay-payment-wechat___SC"
    }]
  }, {
    path: "/SC/pay/shipping",
    component: _aadc911c,
    name: "pay-shipping___SC",
    children: [{
      path: "address",
      component: _924bb5cc,
      name: "pay-shipping-address___SC"
    }, {
      path: "address/:id",
      component: _85fab3fc,
      name: "pay-shipping-address-id___SC"
    }]
  }, {
    path: "/SC/recycle/locations",
    component: _5ad71775,
    name: "recycle-locations___SC"
  }, {
    path: "/SC/recycle/pickup",
    component: _34a79af5,
    name: "recycle-pickup___SC"
  }, {
    path: "/SC/shop/cart",
    component: _bfab92a8,
    name: "shop-cart___SC"
  }, {
    path: "/SC/shop/categories",
    component: _6f0b9ca8,
    name: "shop-categories___SC"
  }, {
    path: "/ZH/mobile/contact-us",
    component: _56e3f08b,
    name: "mobile-contact-us___ZH"
  }, {
    path: "/ZH/pay/payment",
    component: _78d74df2,
    name: "pay-payment___ZH",
    children: [{
      path: "alipay",
      component: _2cc0f692,
      name: "pay-payment-alipay___ZH"
    }, {
      path: "card",
      component: _4ffdf3bd,
      name: "pay-payment-card___ZH"
    }, {
      path: "paypal",
      component: _5857fce0,
      name: "pay-payment-paypal___ZH"
    }, {
      path: "wechat",
      component: _795e50da,
      name: "pay-payment-wechat___ZH"
    }]
  }, {
    path: "/ZH/pay/shipping",
    component: _aadc911c,
    name: "pay-shipping___ZH",
    children: [{
      path: "address",
      component: _924bb5cc,
      name: "pay-shipping-address___ZH"
    }, {
      path: "address/:id",
      component: _85fab3fc,
      name: "pay-shipping-address-id___ZH"
    }]
  }, {
    path: "/ZH/recycle/locations",
    component: _5ad71775,
    name: "recycle-locations___ZH"
  }, {
    path: "/ZH/recycle/pickup",
    component: _34a79af5,
    name: "recycle-pickup___ZH"
  }, {
    path: "/ZH/shop/cart",
    component: _bfab92a8,
    name: "shop-cart___ZH"
  }, {
    path: "/ZH/shop/categories",
    component: _6f0b9ca8,
    name: "shop-categories___ZH"
  }, {
    path: "/KO/article/details/:id?",
    component: _1928fcb2,
    name: "article-details-id___KO"
  }, {
    path: "/KO/shop/category/:id?",
    component: _76f23835,
    name: "shop-category-id___KO"
  }, {
    path: "/KO/shop/details/:id?",
    component: _4183b111,
    name: "shop-details-id___KO"
  }, {
    path: "/SC/article/details/:id?",
    component: _1928fcb2,
    name: "article-details-id___SC"
  }, {
    path: "/SC/shop/category/:id?",
    component: _76f23835,
    name: "shop-category-id___SC"
  }, {
    path: "/SC/shop/details/:id?",
    component: _4183b111,
    name: "shop-details-id___SC"
  }, {
    path: "/ZH/article/details/:id?",
    component: _1928fcb2,
    name: "article-details-id___ZH"
  }, {
    path: "/ZH/shop/category/:id?",
    component: _76f23835,
    name: "shop-category-id___ZH"
  }, {
    path: "/ZH/shop/details/:id?",
    component: _4183b111,
    name: "shop-details-id___ZH"
  }, {
    path: "/article/details/:id?",
    component: _1928fcb2,
    name: "article-details-id___EN"
  }, {
    path: "/KO/article/:type",
    component: _6cecbdd4,
    name: "article-type___KO"
  }, {
    path: "/SC/article/:type",
    component: _6cecbdd4,
    name: "article-type___SC"
  }, {
    path: "/shop/category/:id?",
    component: _76f23835,
    name: "shop-category-id___EN"
  }, {
    path: "/shop/details/:id?",
    component: _4183b111,
    name: "shop-details-id___EN"
  }, {
    path: "/ZH/article/:type",
    component: _6cecbdd4,
    name: "article-type___ZH"
  }, {
    path: "/KO/article/:type/tablet",
    component: _35a0b65c,
    name: "article-type-tablet___KO"
  }, {
    path: "/SC/article/:type/tablet",
    component: _35a0b65c,
    name: "article-type-tablet___SC"
  }, {
    path: "/ZH/article/:type/tablet",
    component: _35a0b65c,
    name: "article-type-tablet___ZH"
  }, {
    path: "/article/:type",
    component: _6cecbdd4,
    name: "article-type___EN"
  }, {
    path: "/article/:type/tablet",
    component: _35a0b65c,
    name: "article-type-tablet___EN"
  }, {
    path: "/",
    component: _4a431436,
    name: "index___EN"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
