//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'FloatingAnimation',
  props: {
    range: { type: Number, default: 40 },
    // range: { type: Number, default: 0 },
    minTime: { type: Number, default: 2000 },
    maxTime: { type: Number, default: 5000 },
  },
  data() {
    return {
      duration: 2000,
      nowPosition: { x: 0, y: 0 },
      timeout: null,
    }
  },
  mounted() {
    this.createAnimation()
  },
  unmounted() {
    this.clearTimeout()
  },
  methods: {
    randomMovingTime() {
      return this.minTime + Math.random() * (this.maxTime - this.minTime)
    },
    randomPosition() {
      const random = () => Math.random() * 2 - 1
      return {
        x: random() * this.range,
        y: random() * this.range,
      }
    },
    clearTimeout() {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
    },
    createAnimation() {
      this.clearTimeout()
      const time = this.randomMovingTime()
      this.duration = time
      this.nowPosition = this.randomPosition()
      this.timeout = setTimeout(
        () => this.createAnimation(),
        Math.max(time - 100, 100)
      )
    },
  },
}
