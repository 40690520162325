//
//
//
//
//
//
//

export default {
  name: 'FooterPolicy',
}
