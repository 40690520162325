import { NuxtAxiosInstance } from '@nuxtjs/axios'
import config from '~/nuxt.config'
import {
  CreatePaymentRequest,
  RegisterInfo,
  UserInfo,
  UserInfoAvatar,
} from '~/api/structures'

class Api {
  public axios: NuxtAxiosInstance
  public host: String

  constructor(axios: NuxtAxiosInstance) {
    this.axios = axios
    this.host = ''
  }

  public CountriesList() {
    return this.axios.get('/api/client/v2/location?type=country')
  }
  public StatesList(token: string, c: string) {
    return this.axios.get(`/api/client/v2/location?type=state`, {
      params: { c: c },
      headers: { Authorization: token },
    })
  }
  public CitiesList(token: string, c: string, s: string) {
    return this.axios.get('/api/client/v2/location?type=city', {
      params: { c: c, s: s },
      headers: { Authorization: token },
    })
  }

  public updatePasswd(token: string, passwd: string) {
    return this.axios.post('/api/client/v1/forget/passwd/verify/', {
      token,
      passwd,
    })
  }

  public sendForgotPasswd(email: string, language: string) {
    return this.axios.post('/api/client/v1/forget/passwd/', {
      email,
      language,
    })
  }

  public categoryList() {
    return this.axios.get('/api/shop/v1/category/list/')
  }

  public login(userid: string, passwd: string) {
    return this.axios.post('/api/client/v1/login/', {
      userid,
      passwd,
    })
  }

  public register(info: RegisterInfo) {
    return this.axios.post('/api/client/v1/register/', info)
  }

  public verifyEmail(email: string) {
    return this.axios.post('/api/client/v1/register/email/', {
      email,
    })
  }

  public getProfile() {
    return this.axios.get('/api/client/v1/profile/')
  }

  public updateProfile(profile: UserInfo) {
    return this.axios.patch('/api/client/v1/profile/update/', profile)
  }

  public updateAvatar(profile: UserInfoAvatar) {
    const { avatar } = profile
    const formData = new FormData()
    formData.append('avatar', avatar)
    return this.axios.patch('/api/client/v1/profile/update/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  // eslint-disable-next-line camelcase
  public proxyLogin(client_id: string) {
    return this.axios.post('/api/client/v1/proxy/login/', { client_id })
  }

  public getCategories(lang = 'EN') {
    return this.axios.get('/api/shop/v1/category/list/', {
      params: { language: lang },
    })
  }

  public getProducts(category: number, currency?: number) {
    return this.axios.get('/api/shop/v1/list/', {
      params: { category, currency },
    })
  }

  public getProductDetail(id: string, currency?: number) {
    return this.axios.get(`/api/shop/v1/detail/${id}/`, {
      params: { currency },
    })
  }

  public getOrders() {
    return this.axios.get('/api/order/v1/order/list/')
  }

  public getTokenRate(currency: number) {
    return this.axios.get('/api/order/v1/token/rate/', {
      params: { currency },
    })
  }

  public editCart(
    action: 'add' | 'del' | 'remove',
    productId: string,
    options = {}
  ) {
    return this.axios.post('/api/order/v1/cart/action/', {
      action,
      product: productId,
      ...options,
    })
  }

  public getCart(currency?: number) {
    return this.axios.get('/api/order/v1/cart/list/', {
      params: { currency },
    })
  }

  public getAddresses() {
    return this.axios.get('/api/order/v1/address/list/')
  }

  public getAddress(id: string) {
    return this.axios.get(`/api/order/v1/address/retrieve/${id}/`)
  }

  public deleteAddress(token: string, id: string) {
    return this.axios.delete(`/api/order/v1/address/destroy/${id}/`, {
      headers: { Authorization: token },
    })
  }

  public updateAddress(token: string, id: string, address: any) {
    return this.axios.patch(`/api/order/v1/address/update/${id}/`, address, {
      headers: { Authorization: token },
    })
  }

  public createAddress(token: string, address: any) {
    return this.axios.post('/api/order/v1/address/create/', address, {
      headers: { Authorization: token },
    })
  }

  public startGoogleLogin() {
    return this.axios.get('/api/client/v1/google/login/')
  }

  public CallbackGoogleLogin(query: string) {
    return this.axios.get('/api/client/v1/google/callback/?' + query)
  }

  // eslint-disable-next-line camelcase
  public createPayment(data: CreatePaymentRequest) {
    return this.axios.post('/api/order/v1/payment/create/', data)
  }

  // eslint-disable-next-line camelcase
  public getPayment() {
    return this.axios.get('/api/order/v1/payment/list/')
  }

  // eslint-disable-next-line camelcase
  public startPayment(pay_type: number) {
    return this.axios.post('/api/order/v1/payment/start/', {
      pay_type,
    })
  }

  // eslint-disable-next-line camelcase
  public updatePaymentAddress(token: string, address: number) {
    return this.axios.patch(
      '/api/order/v1/payment/update/',
      {
        address,
      },
      {
        headers: { Authorization: token },
      }
    )
  }

  public statusPayment(index: string) {
    return this.axios.get('/api/order/v1/payment/status/?index=' + index)
  }

  public paypalExecute(paymentId: string, PayerID: string, token: string) {
    return this.axios.post('/api/order/v1/paypal/execute/', {
      paymentId,
      PayerID,
      token,
    })
  }

  public getRecycleLocation() {
    return this.axios.get('/api/client/v1/redemption/location/')
  }

  public getArticleList(
    type: 'product' | 'tech' | 'news',
    language = 'EN',
    page = 0,
    size = 20
  ) {
    return this.axios.get('/api/article/v1/list/', {
      params: { type, language, page, size },
    })
  }

  public getArticle(id: string) {
    return this.axios.get(`/api/article/v1/detail/${id}/`)
  }

  public getArticleTop(language = 'EN', types = 'product,tech,news') {
    return this.axios.get('/api/article/v1/top/list/', {
      params: { types, language },
    })
  }

  public destroy(passwd: string) {
    return this.axios.post('/api/client/v1/user/discard/', { passwd })
  }

  // validate the promise if fine and return response data
  public static validate(res: any) {
    if (res && !String(res.status).startsWith('2')) {
      throw new Error(`Network Error: ${res.status}`)
    }
    if (res?.data?.status && res.data.status !== 0) {
      throw new Error(`${res.data.msg}`)
    }
    return res.data
  }
}

export default Api
