import KO from './KO.js'
import SC from './SC.js'
import ZH from './ZH.js'
import EN from './EN.js'

export const langList = [
  { code: 'EN', name: 'English', iso: 'en-US', short: 'ENG' },
  { code: 'KO', name: '한국어', iso: 'ko_KR', short: '한국어' },
  { code: 'SC', name: '简体中文', iso: 'zh_CN', short: '简中' },
  { code: 'ZH', name: '繁體中文', iso: 'zh_HK', short: '繁中' },
  // { code: 'jp', name: '日本語' },
]

export const I18N = {
  locales: [...langList.map((v) => v.code)],
  defaultLocale: 'EN',
  vueI18n: {
    fallbackLocale: 'EN',
    messages: { EN, KO, SC, ZH },
  },
  // useRedirectCookie: true,
  // detectBrowserLanguage: {
  //   useCookie: true,
  //   cookieKey: 'i18n_redirected',
  //   alwaysRedirect: true,
  // },
}
