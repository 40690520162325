//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue'
import Cookies from '@/components/utils/Cookies'
import Footer from '../components/Footer'
import MobileTitle from '../components/index/MobileTitle'
import Ebatte from '../components/index/Ebatte'
import MobileBatteriesIntro from '../components/index/MobileBatteriesIntro'
import NavBar from '~/components/nav-bar/NavBar'

export default Vue.extend({
  components: {
    MobileBatteriesIntro:()=>import('../components/index/MobileBatteriesIntro'),
    MobileTitle:()=>import('../components/index/MobileTitle'),
    Footer:()=>import('../components/Footer'),
    NavBar:()=>import('~/components/nav-bar/NavBar'),
    Ebatte:()=>import('../components/index/Ebatte'),
    Cookies:()=>import('@/components/utils/Cookies'),
    // MobileBatteriesIntro,
    // MobileTitle,
    // Footer,
    // NavBar,
    // Ebatte,
    // Cookies,
  },
  data() {
    return {
      options: {
        licenseKey: 'BCC7FE53-C18D4780-9A948A6E-76E8270E',
        anchors: [
          'intro',
          'batteries',
          'mission',
          'howitwork',
          'problem',
          'reason',
          'footer',
        ],
        scrollOverflow: true,
      },
      VideoPaused: true,
      playerOptions: {
        muted: false,
        language: 'en',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            type: 'video/mp4',
            src: 'https://cdn.ebatte.com/video/formal.mp4',
          },
        ],
        poster: 'https://cdn.ebatte.com/static/poster.png',
      },
    }
  },
  methods: {
    updatePaused(event) {
      this.VideoPaused = event.target.paused
    },
  },
})
