//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SectionNavigator',
  props: {
    now: { type: Number, default: 0 },
    items: { type: Array, default: () => [] },
  },
  data() {
    return {
      size: 85,
      // now: 0,
    }
  },
  methods: {
    canNext() {
      return this.now + 1 < this.items.length
    },
    canBefore() {
      return this.now > 0
    },
    handleNext() {
      if (this.canNext()) this.$emit('change', this.now + 1)
    },
    handleBefore() {
      if (this.canBefore()) this.$emit('change', this.now - 1)
    },
    handleClick(num) {
      this.$emit('change', num)
    },
  },
}
