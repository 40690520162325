import { render, staticRenderFns } from "./no-cookies.vue?vue&type=template&id=1ff1480b&scoped=true&"
import script from "./no-cookies.vue?vue&type=script&lang=js&"
export * from "./no-cookies.vue?vue&type=script&lang=js&"
import style0 from "./no-cookies.vue?vue&type=style&index=0&id=1ff1480b&lang=scss&scoped=true&"
import style1 from "./no-cookies.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ff1480b",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavBar: require('/var/www/ebatte/offical-site/components/nav-bar/NavBar.vue').default})
