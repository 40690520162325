import Vue from 'vue'
import {
  StripeCheckout,
  StripeElements,
  StripeElementsSplited,
} from '@winry/vue-stripe-checkout'

export default () => {
  Vue.component('StripeCheckout', StripeCheckout)
  Vue.component('StripeElements', StripeElements)
  Vue.component('StripeElementsSplited', StripeElementsSplited)
}
