import { render, staticRenderFns } from "./mobile.vue?vue&type=template&id=4bd7a47a&scoped=true&"
import script from "./mobile.vue?vue&type=script&lang=js&"
export * from "./mobile.vue?vue&type=script&lang=js&"
import style0 from "./mobile.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./mobile.vue?vue&type=style&index=1&id=4bd7a47a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bd7a47a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavBar: require('/var/www/ebatte/offical-site/components/nav-bar/NavBar.vue').default,MobileBatteriesIntro: require('/var/www/ebatte/offical-site/components/index/MobileBatteriesIntro.vue').default,Ebatte: require('/var/www/ebatte/offical-site/components/index/Ebatte.vue').default,MobileTitle: require('/var/www/ebatte/offical-site/components/index/MobileTitle.vue').default,Footer: require('/var/www/ebatte/offical-site/components/Footer.vue').default})
