









import Vue from 'vue'
import { mapState } from 'vuex'

export default Vue.extend({
  name: 'Cart',
  computed: {
    ...mapState('shop', ['carts']),
    length() {
      return this.carts.reduce((old: number, val: any) => old + val.quantity, 0)
    },
  },
  mounted() {
    this.$store.dispatch('shop/UPDATE_CART')
  },
})
