import Api from '~/api/network'

export interface Article {}

export interface ArticleState {
  productPage: number
  product: Array<Article>
  productTop: Array<Article>
  techPage: number
  tech: Array<Article>
  techTop: Array<Article>
  newsPage: number
  news: Array<Article>
  newsTop: Array<Article>
}

export const state = () => ({
  productPage: 1,
  product: [],
  productTop: [],
  techPage: 1,
  tech: [],
  techTop: [],
  newsPage: 1,
  news: [],
  newsTop: [],
})

type Type = 'product' | 'tech' | 'news'

interface Payload {
  type: Type
  articles: Array<Article>
}

export const mutations = {
  SET_ARTICLE(state: ArticleState, { type, articles }: Payload) {
    state[type] = articles
    // @ts-ignore
    state[`${type}Page`] = 1
  },
  CLEAR_ARTICLE(state: ArticleState, { type }: { type: Type }) {
    state[type] = []
    // @ts-ignore
    state[`${type}Page`] = 1
  },
  ADD_ARTICLE(state: ArticleState, { type, articles }: Payload) {
    state[type] = [...state[type], ...articles]
    // @ts-ignore
    state[`${type}Page`] += 1
  },
  SET_END(state: ArticleState, { type }: { type: Type }) {
    // @ts-ignore
    state[`${type}Page`] = -1
  },
  UPDATE_TOP(
    state: ArticleState,
    {
      product,
      tech,
      news,
    }: {
      product?: Array<Article>
      tech?: Array<Article>
      news?: Array<Article>
    }
  ) {
    state.productTop = product ?? state.productTop
    state.techTop = tech ?? state.techTop
    state.newsTop = news ?? state.newsTop
  },
}

export const actions = {
  async UPDATE_ARTICLE({ commit, state }: any, { type }: { type: Type }) {
    // @ts-ignore
    const api = new Api(this.$axios)
    // @ts-ignore
    const language = this.$i18n.locale
    const res = await api.getArticleList(type, language, 1)
    const { results } = Api.validate(res)
    commit('SET_ARTICLE', { type, articles: results })
  },

  async LOAD_ARTICLE({ commit, state }: any, { type }: { type: Type }) {
    // @ts-ignore
    const api = new Api(this.$axios)
    // @ts-ignore
    const language = this.$i18n.locale
    const page = state[type + 'Page']
    if (page < 0) {
      // already end..
      return
    }
    const res = await api.getArticleList(type, language, page + 1)
    const { results } = Api.validate(res)
    commit('ADD_ARTICLE', { type, articles: results })
  },

  async UPDATE_TOP({ commit }: any) {
    // @ts-ignore
    const api = new Api(this.$axios)
    // @ts-ignore
    const language = this.$i18n.locale
    const res = await api.getArticleTop(language)
    const data = Api.validate(res)
    commit('UPDATE_TOP', data)
  },
}
