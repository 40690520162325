export default [
  {
    key: 'e-Commerce',
    name: 'e-Commerce',
    icon: 'https://cdn.ebatte.com/data/static/icons/index/icons-01-eCommerce.svg',
    animationIcon: '/animations/index/icons/01_e-Commerce/data.json',
    textColor: '#b8dfff',
    backgroundColor: '#6ca0d1',
    backgroundImg: 'https://cdn.ebatte.com/data/static/icons/index/icons-02-RentalService.svg',
    mainColor: '#2772AF',
    lightColor: '#A8C8E0',
  },
  {
    key: 'Rental Service',
    name: 'Rental Service',
    icon: 'https://cdn.ebatte.com/data/static/icons/index/icons-02-RentalService.svg',
    animationIcon: '/animations/index/icons/02_Rental_Service/data.json',
    textColor: '#b1dcff',
    backgroundColor: '#365572 ',
    backgroundImg: 'https://cdn.ebatte.com/data/static/imgs/index/illusions/2-rental-service.svg',
    mainColor: '#6097C2',
    lightColor: '#93BCCC',
  },
  {
    key: 'User Interactive',
    name: 'User Interactive',
    icon: 'https://cdn.ebatte.com/data/static/icons/index/icons-03-UserInteractive.svg',
    animationIcon: '/animations/index/icons/03_User_Interactive/data.json',
    textColor: '#c9f9a5',
    backgroundColor: '#99C777',
    backgroundImg: '/imgs/index/illusions/3-user-interactive.svg',
    mainColor: '#73AA4A',
    lightColor: '#C8E1B7',
  },
  {
    key: 'Water-based Battery',
    name: 'Water-based Battery',
    icon: 'https://cdn.ebatte.com/data/static/icons/index/icons-04-WaterbasedBattery.svg',
    animationIcon: '/animations/index/icons/04_Water-based_Battery/data.json',
    textColor: '#bae0ff',
    backgroundColor: '#4087c0',
    backgroundImg: 'https://cdn.ebatte.com/data/static/imgs/index/illusions/4-waterbased-battery.svg',
    mainColor: '#4E97D1',
    lightColor: '#749FB3',
  },
  {
    key: 'Recycle Reward',
    name: 'Recycle Reward',
    icon: 'https://cdn.ebatte.com/data/static/icons/index/icons-05-RecycleReward.svg',
    animationIcon: '/animations/index/icons/05_Recycle_Reward/data.json',
    textColor: '#ffb877',
    backgroundColor: '#edad67',
    backgroundImg: 'https://cdn.ebatte.com/data/static/imgs/index/illusions/5-recycle-reward.svg',
    mainColor: '#E79143',
    lightColor: '#BFBAB6',
  },
  {
    key: 'Content',
    name: 'Content',
    icon: 'https://cdn.ebatte.com/data/static/icons/index/icons-06-Content.svg',
    animationIcon: '/animations/index/icons/06_Content/data.json',
    textColor: '#70dee4',
    backgroundColor: '#319CA3',
    backgroundImg: 'https://cdn.ebatte.com/data/static/imgs/index/illusions/6-content.svg',
    mainColor: '#319CA3',
    lightColor: '#B2D1C4',
  },
  {
    key: 'Warranty Service',
    name: 'Warranty Service',
    icon: 'https://cdn.ebatte.com/data/static/icons/index/icons-07-WarrantyService.svg',
    animationIcon: '/animations/index/icons/07_Warranty_Service/data.json',
    textColor: '#ffb877',
    backgroundColor: '#f1c764',
    backgroundImg: 'https://cdn.ebatte.com/data/static/imgs/index/illusions/7-warranty-service.svg',
    mainColor: '#D1792A',
    lightColor: '#E6B673',
  },
  {
    key: 'AI Research & Analysis',
    name: 'AI Research & Analysis',
    icon: 'https://cdn.ebatte.com/data/static/icons/index/icons-08-AIResearch&Analysis.svg',
    animationIcon: '/animations/index/icons/08_AI Research_Analysis/data.json',
    textColor: '#d3ebff',
    backgroundColor: '#6f92ad',
    backgroundImg: 'https://cdn.ebatte.com/data/static/imgs/index/illusions/8-ai-research.svg',
    mainColor: '#6F92AD',
    lightColor: '#99B6CC',
  },
  {
    key: 'Recycle Service',
    name: 'Recycle Service',
    icon: 'https://cdn.ebatte.com/data/static/icons/index/icons-09-RecycleService.svg',
    animationIcon: '/animations/index/icons/09_Recycle_Service/data.json',
    textColor: '#c9f9a5',
    backgroundColor: '#90be6d',
    backgroundImg: 'https://cdn.ebatte.com/data/static/imgs/index/illusions/9-recycle-service.svg',
    mainColor: '#73AA4A',
    lightColor: '#D3E0CA',
  },
  {
    key: 'Product Design',
    name: 'Product Design',
    icon: 'https://cdn.ebatte.com/data/static/icons/index/icons-10-ProductDesign.svg',
    animationIcon: '/animations/index/icons/10_Product_Design/data.json',
    textColor: '#d3ebff',
    backgroundColor: '#6f92ad',
    backgroundImg: 'https://cdn.ebatte.com/data/static/imgs/index/illusions/10-product-Design.svg',
    mainColor: '#6F92AD',
    lightColor: '#8FB1CC',
  },
]
