//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AnimationMixin from '@/mixins/AnimationMixin'
import titleAmin from 'static/animations/index/battery-tech/title/data.json'

const AUTO_NEXT_PAGE_TIME = 5000

export default {
  name: 'IndexBatteryTechnology',
  mixins: [AnimationMixin],
  data() {
    return {
      totalPageNum: 4,
      nowPage: 1,
      titleAnimationOptions: {
        loop: false,
        animationData: titleAmin,
        assetsPath: 'animations/index/battery-tech/title/images/',
      },
    }
  },
  mounted() {
    setInterval(() => this.playNext(), AUTO_NEXT_PAGE_TIME)
  },
  methods: {
    range: (num) => [...Array(num).keys()],
    startAnimation() {},
    next() {
      if (this.nowPage < this.totalPageNum) {
        this.nowPage += 1
      }
    },
    before() {
      if (this.nowPage > 1) {
        this.nowPage -= 1
      }
    },
    playNext() {
      this.nowPage = (this.nowPage % this.totalPageNum) + 1
    },
    switchTo(num) {
      this.nowPage = num
    },
  },
}
