
























































































import Vue from 'vue'
import { langList } from '~/lang'
import { currencies, CURRENCY_KEY } from '~/models/currency'
import { getCurrencyCode, setCurrencyCode } from '~/utils/currency'
import { getLangByCode } from '~/utils/lang'

export default Vue.extend({
  name: 'LangSwitch',
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showPanel: false,
    langList,
    currencies,
    nowSelectCurrency: '',
  }),
  computed: {
    nowLang() {
      const lang = getLangByCode(this.$i18n.locale)
      return lang ? lang.short : 'ENG'
    },
  },
  mounted() {
    this.nowSelectCurrency = getCurrencyCode(this)
    // @ts-ignore
    this.$auth.$storage.watchState(CURRENCY_KEY, (newValue: string) => {
      this.nowSelectCurrency = newValue
    })
  },
  methods: {
    togglePanel() {
      this.showPanel = !this.showPanel
    },
    apply() {
      this.showPanel = false
    },
    async changeLang(code: string) {
      await this.$i18n.setLocale(code)
      this.$router.push(this.switchLocalePath(code))
      // 别重新刷新了，有Bug，还是监听语言变量被修改吧
      // setTimeout(() => this.reload(), 5000)
    },
    async changeCurrency(code: string) {
      await setCurrencyCode(this, code)
      this.reload()
    },
    clickLangHandler(event: any) {
      const code = event.currentTarget.dataset.code
      this.changeLang(code)
    },
    changeDesktopCurrencyHandler(code: string) {
      this.changeCurrency(code)
    },
    clickMobileCurrencyHandler(event: any) {
      const code = event.currentTarget.dataset.code
      this.changeCurrency(code)
    },
    reload() {
      if (process.client) {
        setTimeout(() => location.reload(), 200)
      }
    },
  },
})
