












































import { PropType } from 'vue'
import { UserInfo } from '~/store/user'

export default {
  name: 'UserInfo',
  props: {
    user: { type: Object as PropType<UserInfo>, default: null },
    isMobile: { type: Boolean, default: false },
  },
  methods: {
    logout() {
      // @ts-ignore
      this.$emit('quit')
    },
  },
}
