
























































































































import Vue from 'vue'
import ColoredNavBtn from '~/components/nav-bar/ColoredNavBtn.vue'
import Logo from '~/components/Logo.vue'
import LangSwitch from '~/components/nav-bar/LangSwitch.vue'
import UserInfo from '~/components/nav-bar/UserInfo.vue'
import Cart from '~/components/nav-bar/Cart.vue'

export default Vue.extend({
  name: 'NavBar',
  components: { Cart, UserInfo, LangSwitch, Logo },
  props: {
    blur: { type: Boolean, default: false },
    mobileBgColor: { type: String, default: 'black' },
  },
  data: () => ({
    hasMobileMenuModal: false,
    hasMobileLangModal: false,
  }),
  computed: {
    user(): any {
      // @ts-ignore
      return this.$auth.user
    },
  },
  methods: {
    showMobileMenuModal() {
      this.hasMobileMenuModal = true
    },
    showMobileLangModal() {
      this.hasMobileLangModal = true
    },
    hide() {
      this.hasMobileMenuModal = false
      this.hasMobileLangModal = false
    },
    logout() {
      this.$store.dispatch('user/LOGOUT')
      this.$router.push(this.localePath('/'))
    },
  },
})
