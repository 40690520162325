//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import IntroIconBadge from 'assets/imgs/index/IntroIconBadge'

export default {
  name: 'P8AiResearchAndAnalysis',
  components: { IntroIconBadge },
}
