














export default {
  name: 'Input',
  props: {
    inputType: { type: String, default: 'text' },
    name: { type: String, default: null },
    title: { type: String, default: '' },
    value: { type: String, default: '' },
  },
}
