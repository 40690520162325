/**
 *
 * @param {*} UA ,就是userAgent
 * @returns type: 设备类型
 *          env: 访问环境(微信/微博/qq)
 */

function isWechat(UA: string) {
  return /MicroMessenger/i.test(UA)
}

function isWeibo(UA: string) {
  return /Weibo/i.test(UA)
}

function isQQ(UA: string) {
  return /QQ/i.test(UA)
}

function isMobile(UA: string) {
  return /(Android|webOS|iPhone|iPod|tablet|BlackBerry|Mobile)/i.test(UA)
}

function isIOS(UA: string) {
  return /iPhone|iPad|iPod/i.test(UA)
}

function isAndroid(UA: string) {
  return /Android/i.test(UA)
}

function judgeApplication(UA: string) {
  if (isWechat(UA)) {
    return 'wechat'
  }

  if (isWeibo(UA)) {
    return 'weibo'
  }

  if (isQQ(UA)) {
    return 'qq'
  }

  return undefined
}

function judgePlatform(UA: string) {
  if (isMobile(UA)) {
    if (isIOS(UA)) {
      return 'ios'
    }
    if (isAndroid(UA)) {
      return 'android'
    }
    return 'mobile'
  }
  return 'pc'
}

export function deviceType(UA: string) {
  return {
    isMobile: isMobile(UA),
    platform: judgePlatform(UA),
    application: judgeApplication(UA),
  }
}
