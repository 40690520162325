//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SectionNavigator from '@/components/index/SectionNavigator'
import DetailsContent from '@/components/index/DetailsContent'

export default {
  name: 'FloatDetails',
  components: {
    DetailsContent,
    SectionNavigator,
  },
  props: {
    items: { type: Array, default: () => [] },
    now: { type: Number, default: 0 },
    show: { type: Boolean, default: true },
  },
  data() {
    return {
      // now: 0,
    }
  },
  methods: {
    changePage(num) {
      // this.now = num
      this.$emit('change', num)
    },
    handleClose() {
      this.$emit('close')
    },
  },
}
