






export default {
  name: 'MobileTitle',
}
