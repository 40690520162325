//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import Cookies from '@/components/utils/Cookies'
// import Footer from '../components/Footer'
export default {
  components: { 
     NavBar:() => import('@/components/utils/Cookies.vue'),
    Cookies:() => import('~/components/nav-bar/NavBar.vue')
    // Footer, Cookies 
    },
  head() {
    return {
      title: 'Ebatte',
    }
  },
}
