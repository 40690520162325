//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'IndexDownload',
  data: function () {
    return {
      downloadPic: require('../../assets/imgs/index/download@2x.webp'),
      mobileTitle: require('../../assets/imgs/index/title-mobile.webp'),
      mobilePic: require('../../assets/imgs/index/mobile.webp'),
    }
  },
}
