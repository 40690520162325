//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const SHOW_ALLOW_COOKIES_KEY = 'hideAllowCookies'

export default {
  name: 'Cookies',
  data() {
    return {
      show: false,
    }
  },
  mounted() {
    const val = localStorage.getItem(SHOW_ALLOW_COOKIES_KEY)
    if (val === null || val === undefined) {
      this.show = true
      return
    }
    this.show = val !== 'true'
  },
  methods: {
    allow() {
      this.show = false
      localStorage.setItem(SHOW_ALLOW_COOKIES_KEY, 'true')
    },
  },
}
