import { currencies, CURRENCY_KEY, defaultCurrency } from '~/models/currency'

export function getCurrencyIndexByCode(code: string) {
  return currencies.findIndex((value) => value.code === code)
}

export function getCurrencyByIndex(index: number) {
  return currencies[index]
}

export function getCurrencyByCode(code: string) {
  return currencies.find((value) => value.code === code)
}

export function getCurrencyCode(context: any) {
  // Usage: `getCurrencyCode(this)` in Vue Components
  // `getCurrencyCode(context)` in SSR
  return context.$auth.$storage.getUniversal(CURRENCY_KEY) ?? defaultCurrency
}

export function getCurrency(context: any) {
  // Usage: `getCurrency(this)` in Vue Components
  // `getCurrency(context)` in SSR
  const code = getCurrencyCode(context)
  return getCurrencyByCode(code)
}

export function getCurrencyIndex(context: any) {
  // Usage: `getCurrency(this)` in Vue Components
  // `getCurrency(context)` in SSR
  const code = getCurrencyCode(context)
  return getCurrencyIndexByCode(code)
}

export async function setCurrencyCode(context: any, code: string) {
  // Usage: `setCurrencyCode(this)` in Vue Components
  // `setCurrencyCode(context)` in SSR
  await context.$auth.$storage.setUniversal(CURRENCY_KEY, code)
  const store = context.$store ?? context.store
  await store.dispatch('shop/UPDATE_TOKEN_RATE')
}
