import { render, staticRenderFns } from "./article.vue?vue&type=template&id=f243b6b2&scoped=true&"
import script from "./article.vue?vue&type=script&lang=js&"
export * from "./article.vue?vue&type=script&lang=js&"
import style0 from "./article.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./article.vue?vue&type=style&index=1&id=f243b6b2&lang=scss&scoped=true&"
import style2 from "./article.vue?vue&type=style&index=2&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f243b6b2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavBar: require('/var/www/ebatte/offical-site/components/nav-bar/NavBar.vue').default,Cookies: require('/var/www/ebatte/offical-site/components/utils/Cookies.vue').default})
