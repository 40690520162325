import Api from '~/api/network'
import { getCurrencyIndex } from '~/utils/currency'
import { CreatePaymentRequest, PaymentInfo } from '~/api/structures'
/* eslint-disable camelcase */

export interface ProductCategory {
  id: string
  name: String
  picture_url: String
  priority: number
}

export interface ProductImage {
  id: string
  img_url: String
  thumbnail_url: String
  priority: number
}

export enum Currency {
  USD,
  UKD,
  WON,
}

export interface ProductPrice {
  id: string
  currency: Currency
  price: number
  compare_price: number
}

export interface ProductBrief {
  id: string
  title: String
  prices: ProductPrice
  pictures: Array<ProductImage>
  quantity: number
}

export interface ProductDetailed {
  id: string
  title: String
  prices: Array<ProductPrice>
  pictures: Array<ProductImage>
  quantity: String
  company: string
}

export interface Cart {
  id: string
  product: ProductDetailed
  quantity: number
  created: String
  updated: String
  options: object
}

export interface ShopState {
  categories: Array<ProductCategory>
  products: Array<ProductBrief>
  carts: Array<Cart>
  payment: PaymentInfo
  tokenRate: number
  tokenRateCurrency: number
  paymentStatus: PaymentStatus
}

export interface PaymentStatus {
  publishableKey?: string
  clientSecret?: string
  currency?: number
  amount?: number
  err?: string
}

export const state = () => ({
  categories: [],
  products: [],
  carts: [],
  payment: {},
  tokenRate: null,
  tokenRateCurrency: null,
  paymentStatus: {},
})

export const mutations = {
  SET_CATEGORIES(state: ShopState, categories: Array<ProductCategory>) {
    state.categories = categories
  },
  SET_PRODUCTS(state: ShopState, products: Array<ProductBrief>) {
    state.products = products
  },
  SET_CARTS(state: ShopState, carts: Array<Cart>) {
    state.carts = carts
  },
  SET_PAYMENT(state: ShopState, payment: PaymentInfo) {
    state.payment = payment
  },
  SET_PAYMENT_STATUS(state: ShopState, paymentStatus: PaymentStatus) {
    state.paymentStatus = paymentStatus
  },
  SET_PAYMENT_CURRENCY(state: ShopState, currency: number) {
    state.paymentStatus.currency = currency
  },
  SET_TOKEN_RATE(
    state: ShopState,
    {
      tokenRate,
      tokenRateCurrency,
    }: {
      tokenRate: number
      tokenRateCurrency: number
    }
  ) {
    state.tokenRate = tokenRate
    state.tokenRateCurrency = tokenRateCurrency
  },
}

export const actions: any = {
  async UPDATE_CATEGORIES({ commit }: any) {
    const client = new Api(this.$axios)
    const lang = this.$i18n.locale
    const res = await client.getCategories(lang)
    const categories = Api.validate(res)
    commit('SET_CATEGORIES', categories)
  },

  async UPDATE_PRODUCTS({ commit }: any, { categoryId }: any) {
    const client = new Api(this.$axios)
    const currency = getCurrencyIndex(this)
    const res = await client.getProducts(categoryId, currency)
    const products = Api.validate(res)
    commit('SET_PRODUCTS', products)
  },

  async UPDATE_CART({ commit }: any) {
    if (!this.$auth.loggedIn) {
      return
    }
    const client = new Api(this.$axios)
    const currency = getCurrencyIndex(this)
    const res = await client.getCart(currency)
    const carts = Api.validate(res)
    commit('SET_CARTS', carts)
  },

  async EDIT_CART(
    { dispatch }: any,
    payload: {
      action: 'add' | 'del' | 'remove'
      productId: string
      options: object
    }
  ) {
    const client = new Api(this.$axios)
    const res = await client.editCart(
      payload.action,
      payload.productId,
      payload.options
    )
    const editCartResult = Api.validate(res)
    console.log(editCartResult)
    dispatch('UPDATE_CART')
  },

  async UPDATE_TOKEN_RATE({ commit }: any) {
    const client = new Api(this.$axios)
    const currency = getCurrencyIndex(this)
    const res = await client.getTokenRate(currency)
    const { price } = Api.validate(res)
    commit('SET_TOKEN_RATE', { tokenRate: price, tokenRateCurrency: currency })
  },

  async CREATE_PAYMENT(
    { commit, dispatch, state }: any,
    payload: {
      tokenAmount: number
    }
  ) {
    const currency = getCurrencyIndex(this)
    if (currency !== state.tokenRateCurrency) {
      await dispatch('UPDATE_TOKEN_RATE')
    }
    const price = state.carts
      .map((c: Cart) => (c.quantity * c.product.prices[0].price) as number)
      .reduce((a: number, b: number) => a + b, 0)
    const amount = price - payload.tokenAmount * state.tokenRate

    const client = new Api(this.$axios)
    const res = await client.createPayment({
      token_amount: payload.tokenAmount,
      token_rate: state.tokenRate,
      currency,
      amount,
      price,
      type: 'cart',
      // cart_list: state.carts.map((c: Cart) => c.product.id),
    })
    if (!res) {
      commit('SET_PAYMENT', { err: 'You have already created an order!' })
      return
    }
    const { data } = Api.validate(res)
    console.log(data)
    commit('SET_PAYMENT', data)
  },

  async UPDATE_PAYMENT({ commit }: any) {
    const client = new Api(this.$axios)
    const res = await client.getPayment()
    const payments = Api.validate(res)
    commit('SET_PAYMENT', payments.length ? payments[0] : null)
  },

  async UPDATE_PAYMENT_STATUS(
    { commit }: any,
    payload: {
      payType: number
    }
  ) {
    const client = new Api(this.$axios)
    try {
      const res = await client.startPayment(payload.payType ?? 30)
      console.log(res)
      const { data, status } = Api.validate(res)
      console.log('start payment result: ', res)
      if (res.status === 200 && status === 0) {
        commit('SET_PAYMENT_STATUS', {
          publishableKey: data.publishableKey,
          clientSecret: data.clientSecret,
          currency: data.currency,
          amount: data.amount,
        })
      } else {
        console.log('err ', res.data)
        commit('SET_PAYMENT_STATUS', {
          err: res.data.msg,
        })
      }
    } catch (e) {
      console.error('Meet error:', e)
      commit({
        err: JSON.stringify(e),
      })
    }
  },
}
