export const currencies = [
  {
    code: 'USD',
    text: 'USD',
    name: 'United State Dollar(USD)',
    mobile: '🇺🇸 USD',
    symbol: '$',
    accuracy: 2,
  },
  {
    code: 'HKD',
    text: 'HKD',
    name: 'Hong Kong SAR(HKD)',
    mobile: '🇭🇰 HKD',
    symbol: '$',
    accuracy: 2,
  },
  {
    code: 'WON',
    text: 'KRW',
    name: 'Korea KRW(WON)',
    mobile: '🇰🇷 KRW',
    symbol: '₩',
    accuracy: 2,
  },
]

export const defaultCurrency = 'USD'

export const CURRENCY_KEY = 'currencyCode'
