import { render, staticRenderFns } from "./IndexTopSectionMobile.vue?vue&type=template&id=1dc8559c&scoped=true&"
import script from "./IndexTopSectionMobile.vue?vue&type=script&lang=js&"
export * from "./IndexTopSectionMobile.vue?vue&type=script&lang=js&"
import style0 from "./IndexTopSectionMobile.vue?vue&type=style&index=0&id=1dc8559c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1dc8559c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Lottie: require('/var/www/ebatte/offical-site/components/utils/Lottie.vue').default,FloatPoints1: require('/var/www/ebatte/offical-site/components/index/FloatPoints1.vue').default,FloatPoints2: require('/var/www/ebatte/offical-site/components/index/FloatPoints2.vue').default})
