//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import IntroIconBadge from 'assets/imgs/index/IntroIconBadge'

export default {
  name: 'P5RecycleReward',
  components: { IntroIconBadge },
}
