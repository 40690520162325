//
//
//
//
//
//
//

export default {
  name: 'Ebatte',
  props: {
    color: {
      type: String,
      default: '#D9D529',
    },
  },
}
