

















export default {
  name: 'Loge',
  props: {
    width: { type: Number, default: null },
    height: { type: Number, default: null },
  },
}
