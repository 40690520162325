































export default {
  name: 'MobileBatteriesIntro',
}
