import Api from '~/api/network'

export interface UserInfo {
  // eslint-disable-next-line camelcase
  client_info_id: number
  // eslint-disable-next-line camelcase
  client_id: number
  // eslint-disable-next-line camelcase
  first_name: String
  // eslint-disable-next-line camelcase
  last_name: String
  email: String
  // eslint-disable-next-line camelcase
  battery_count: number
  // eslint-disable-next-line camelcase
  account_token: number
}

export interface UserState {
  user: UserInfo
  token: string
  currency: number
}

export const state = () => ({
  user: null,
  token: null,
})

export const mutations = {
  SET_TOKEN(state: UserState, token: string) {
    state.token = token
    console.log('set token', token)
  },
  SET_USER_INFO(state: UserState, user: UserInfo) {
    state.user = user
  },
}

export const actions: any = {
  async LOGIN({ commit, dispatch }: any, { userid, passwd }: any) {
    const client = new Api(this.$axios)
    const res = await client.login(userid, passwd)
    console.log('login result:', res)
    const { token } = Api.validate(res)
    // this.$axios.setToken(token)
    this.$auth.setToken('local', token)
    this.$auth.strategy._setToken(`Bearer ${token}`)
    this.$auth.fetchUser()
    commit('SET_TOKEN', token)
    await dispatch('UPDATE_USER_INFO')
  },

  async CALLBACK_LOGIN({ commit, dispatch }: any, { query }: any) {
    const client = new Api(this.$axios)
    const res = await client.CallbackGoogleLogin(query)
    console.log('login result:', res)
    const { token } = Api.validate(res)
    // this.$axios.setToken(token)
    this.$auth.setToken('local', token)
    this.$auth.strategy._setToken(`Bearer ${token}`)
    this.$auth.fetchUser()
    commit('SET_TOKEN', token)
    await dispatch('UPDATE_USER_INFO')
  },

  async UPDATE_USER_INFO({ commit }: any) {
    const userInfo = await this.$auth.fetchUser()
    commit('SET_USER_INFO', userInfo)
  },

  LOGOUT({ commit }: any) {
    // @ts-ignore
    this.$auth.logout()
    // @ts-ignore
    this.$auth.setToken('local', '')
    // @ts-ignore
    this.$auth.strategy._setToken('')
    commit('SET_USER_INFO', null)
  },
}
