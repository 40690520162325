//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import process from 'static/animations/index/process/data.json'
import processMobile from 'static/animations/index/processMobile/data.json'
import AnimationMixin from '@/mixins/AnimationMixin'
import { getPosition } from '@/utils/get-position'

export default {
  name: 'IndexRecycleEconomy',
  mixins: [AnimationMixin],
  data() {
    return {
      processAnimationOptions: {
        loop: false,
        animationData: process,
        assetsPath: 'animations/index/process/images/',
      },
      processMobileAnimationOptions: {
        loop: false,
        animationData: processMobile,
        assetsPath: 'animations/index/processMobile/images/',
      },
      showImg: 0,
      timeouts: [],
    }
  },
  mounted() {
    const animationWrapper = this.$refs.animationWrapper
    console.log('process item', animationWrapper, getPosition(animationWrapper))
    if (animationWrapper) {
      const { y } = getPosition(animationWrapper)
      this.badgesAnimationWrapperTriggerHeight = y
      console.log('detect animationWrapper from top', y)
    }
    window.addEventListener('scroll', (e) => this.handleScrollEvent(e))
  },
  methods: {
    startAnimation() {
      this.timeouts.forEach((h) => clearTimeout(h))
      this.timeouts = []
      this.showImg = 0
      if (this.animation.process) {
        this.animation.process.goToAndPlay(0)
      }
      if (this.animation.processMobile) {
        this.animation.processMobile.goToAndPlay(0)
      }
      const handle1 = setTimeout(() => {
        this.showImg = 1
      }, 2000)
      const handle2 = setTimeout(() => {
        this.showImg = 2
      }, 2400)
      const handle3 = setTimeout(() => {
        this.showImg = 3
      }, 2800)
      this.timeouts.push(handle1, handle2, handle3)
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    handleScrollEvent(event) {
      // eslint-disable-next-line
      const scrollTop = window.pageYOffset
      const height = window.innerHeight
      console.log('recycle scroll', scrollTop, height)
      if(scrollTop>=2700){
        this.$bus.$emit('closeVideo')
      }

      if (
        !this.hasAnimPlayed &&
        scrollTop > this.badgesAnimationWrapperTriggerHeight - height - 50
      ) {
        this.hasAnimPlayed = true
        this.startAnimation()
        console.log('play process animation')
      }
    },
  },
}
