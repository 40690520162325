//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FloatingAnimation from '@/components/index/FloatingAnimation'

export default {
  name: 'FloatPoints1',
  components: { FloatingAnimation },
}
