//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import P1ECommerce from '@/components/index/pages/P1ECommerce'
import P2RentalService from '@/components/index/pages/P2RentalService'
import P3UserInterface from '@/components/index/pages/P3UserInterface'
import P4WaterbasedBattery from '@/components/index/pages/P4WaterbasedBattery'
import P5RecycleReward from '@/components/index/pages/P5RecycleReward'
import P6Content from '@/components/index/pages/P6Content'
import P7Warranty from '@/components/index/pages/P7Warranty'
import P8AiResearchAndAnalysis from '@/components/index/pages/P8AiResearchAndAnalysis'
import P9RecycleService from '@/components/index/pages/P9RecycleService'
import P10ProductDesign from '@/components/index/pages/P10ProductDesign'
export default {
  name: 'DetailsContent',
  components: {
    P10ProductDesign,
    P9RecycleService,
    P8AiResearchAndAnalysis,
    P7Warranty,
    P6Content,
    P5RecycleReward,
    P4WaterbasedBattery,
    P3UserInterface,
    P2RentalService,
    P1ECommerce,
  },
  props: {
    item: { type: Object, default: () => ({}) },
  },
}
