//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import Cookies from '@/components/utils/Cookies'
// import NavBar from '~/components/nav-bar/NavBar'
export default {
  name: 'BgGray',
  components: {
    NavBar:() => import('@/components/utils/Cookies.vue'),
    Cookies:() => import('~/components/nav-bar/NavBar.vue')
    //  NavBar, Cookies 
     },
  head() {
    return {
      title: 'Ebatte',
    }
  },
}
