//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue'
import FloatDetails from '@/components/index/FloatDetails'
import indexMenuData from '@/data/indexMenuData'
import { getPosition } from '@/utils/get-position'
import ScrollHint from '@/components/index/ScrollHint'
import Footer from '@/components/index/Footer'
// import Loading from '@/components/pay/Loading'
// import CanvasEnergyObj from '@/components/index/CanvasEnergyObj'
import IndexTopSection from '@/components/index/IndexTopSection'
import IndexTopSectionMobile from '@/components/index/IndexTopSectionMobile'
import IndexRecycleEconomy from '@/components/index/IndexRecycleEconomy'
import IndexBatteryTechnology from '@/components/index/IndexBatteryTechnology'
import IndexDownload from '@/components/index/IndexDownload'
import PageLoading from '@/components/utils/PageLoading'
import Cookies from '@/components/utils/Cookies'
import NavBar from '~/components/nav-bar/NavBar'
import futureEnergy from '~/static/animations/future-energy/data.json'

const LOAD_STATE_VALUES = {
  uninitialized: 0,
  loading: 50,
  interactive: 75,
  complete: 100,
}
const LOAD_SPEED = 5
const LOAD_REFRESH_INTERVAL = 100

export default Vue.extend({
  components: {
    IndexDownload: () => import('@/components/index/IndexDownload'),
    IndexBatteryTechnology: () =>
      import('@/components/index/IndexBatteryTechnology'),
    IndexRecycleEconomy: () => import('@/components/index/IndexRecycleEconomy'),
    IndexTopSection: () => import('@/components/index/IndexTopSection'),
    IndexTopSectionMobile: () =>
      import('@/components/index/IndexTopSectionMobile'),
    // CanvasEnergyObj,
    Footer: () => import('@/components/index/Footer'),
    ScrollHint: () => import('@/components/index/ScrollHint'),
    FloatDetails: () => import('@/components/index/FloatDetails'),
    NavBar: () => import('~/components/nav-bar/NavBar'),
    PageLoading: () => import('@/components/utils/PageLoading'),
    Cookies: () => import('@/components/utils/Cookies'),
    // IndexDownload,
    // IndexBatteryTechnology,
    // IndexRecycleEconomy,
    // IndexTopSection,
    // IndexTopSectionMobile,
    // // CanvasEnergyObj,
    // Footer,
    // ScrollHint,
    // FloatDetails,
    // NavBar,
    // PageLoading,
    // Cookies,
  },
  data() {
    return {
      menuItems: indexMenuData,
      earthAnimationOptions: {
        loop: false,
        animationData: futureEnergy,
        assetsPath: 'animations/future-energy/images/',
      },
      nowPage: -1,
      badgesAnimTriggerHeight: 0,
      topPage: 1,
      navBarBlur: false,
      mobileBgColor: '#2e2e33',
      progress: 0,
      loadingGrowHandler: null,
    }
  },
  mounted() {
    if (process.browser) {
      const futureAnim = this.$refs.futureAnim
      console.log('icon item', futureAnim, getPosition(futureAnim))
      if (futureAnim) {
        const { y } = getPosition(futureAnim)
        this.badgesFutureAnimTriggerHeight = y
        console.log('detect futureAnim from top', y)
      }

      window.addEventListener('scroll', (e) => this.handleScrollEvent(e))

      // 每十秒种播放一次动画
      setInterval(() => this.playAllIconAnim(), 3000)

      this.loadingGrowHandler = setInterval(
        () => this.updateProgress(),
        LOAD_REFRESH_INTERVAL
      )
    }
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    handleScrollEvent(event) {
      // eslint-disable-next-line
      const scrollTop = window.pageYOffset
      const height = window.innerHeight
      console.log('scroll', scrollTop, height)

      if (
        !this.hasFutureAnimPlayed &&
        scrollTop > this.badgesFutureAnimTriggerHeight - window.innerHeight - 50
      ) {
        this.hasFutureAnimPlayed = true
        this.playFutureAnim()
        console.log('play future animation')
      }

      this.topPage = scrollTop < height ? 1 : 2
      this.navBarBlur = scrollTop >= height * 2
      this.mobileBgColor = scrollTop >= height ? 'black' : '#2e2e33'
    },
    handleIconAnimation(name, anim) {
      if (!this.iconsAnimation) {
        this.iconsAnimation = {}
      }
      this.iconsAnimation[name] = anim
    },
    handleAnimation(name, anim) {
      if (!this.animation) {
        this.animation = {}
      }
      this.animation[name] = anim
    },
    handleBefore() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    },
    handleNext() {
      const height = window.innerHeight
      window.scrollTo({
        top: height * 1.5,
        behavior: 'smooth',
      })
    },
    playFutureAnim() {
      if (this.animation && this.animation.future) {
        this.animation.future.goToAndPlay(0)
      }
    },
    playAllIconAnim() {
      Object.values(this.iconsAnimation).forEach((anim) => anim.goToAndPlay(0))
    },
    handlePageChange(num) {
      this.nowPage = num
    },
    handleOnClose() {
      this.nowPage = -1
    },
    genIconBadgeAnimationOption(path) {
      return {
        loop: false,
        autoplay: false,
        path,
        // assetsPath: 'animations/future-energy/images/',
      }
    },
    updateProgress() {
      const state = document.readyState
      const minValue = LOAD_STATE_VALUES[state]
      if (minValue >= 100) {
        this.progress = 100
        if (this.loadingGrowHandler) {
          clearInterval(this.loadingGrowHandler)
          this.loadingGrowHandler = null
        }
        return
      }
      const nextIndex = Object.keys(LOAD_STATE_VALUES).indexOf(state) + 1
      const maxValue = Object.values(LOAD_STATE_VALUES)[nextIndex]

      this.progress += (LOAD_REFRESH_INTERVAL / 1000) * LOAD_SPEED
      this.progress = Math.max(Math.min(maxValue, this.progress), minValue)
    },
  },
  head() {
    return {
      title: 'Ebatte',
      // meta: [
      //   {
      //     hid: 'description',
      //     name: 'description',
      //     content: 'My custom description',
      //   },
      // ],
    }
  },
})
