import Vue from 'vue'
import 'fullpage.js/vendors/scrolloverflow'
import Fullpage from 'vue-fullpage.js'
import 'fullpage.js/dist/fullpage.css'
import VueLazyLoad from 'vue-lazyload'
import axios from 'axios'


const Demo = Vue.extend({})
Vue.prototype.$bus = new Demo()
Vue.prototype.$axios = axios

Vue.use(Fullpage)

const loadimg = require('../assets/icons/loading.gif')
const errorimg = require('../assets/icons/error.png')

Vue.use(VueLazyLoad, {
    loading: loadimg,
    erro: errorimg,
    preLoad: 1.2
})



