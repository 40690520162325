//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import Cookies from '@/components/utils/Cookies'
// import Footer from '../components/Footer'
// import NavBar from '~/components/nav-bar/NavBar'
export default {
  components: {
    Footer:() => import('../components/Footer.vue'),
    NavBar:() => import('@/components/utils/Cookies.vue'),
    Cookies:() => import('~/components/nav-bar/NavBar.vue')
    //  Footer, NavBar, Cookies 
     },
  computed: {
    child() {
      return this.$refs.child
    },
  },
  head() {
    return {
      title: 'Ebatte',
      // meta: [
      //   {
      //     hid: 'description',
      //     name: 'description',
      //     content: 'My custom description',
      //   },
      // ],
    }
  },
}
