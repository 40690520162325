var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"index-download--container"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:('https://cdn.ebatte.com/data/assets/imgs/index/page5-dot.webp'),expression:"'https://cdn.ebatte.com/data/assets/imgs/index/page5-dot.webp'"}],staticClass:"bg-img"}),_vm._v(" "),(this.$device.isDesktop)?_c('div',{staticClass:"img-box"},[(this.$device.isDesktop)?_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.downloadPic),expression:"downloadPic"}],staticClass:"content",attrs:{"alt":"download"}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"downloads"},[_c('a',{attrs:{"href":"https://apps.apple.com/cn/app/ebatte/id1527045873"}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
            'https://cdn.ebatte.com/data/assets/imgs/index/download-apple.webp'
          ),expression:"\n            'https://cdn.ebatte.com/data/assets/imgs/index/download-apple.webp'\n          "}]})]),_vm._v(" "),_c('a',{attrs:{"href":"https://play.google.com/store/apps/details?id=org.ebatte"}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
            'https://cdn.ebatte.com/data/assets/imgs/index/download-android.webp'
          ),expression:"\n            'https://cdn.ebatte.com/data/assets/imgs/index/download-android.webp'\n          "}]})])])]):_vm._e(),_vm._v(" "),(this.$device.isMobile)?_c('div',{staticClass:"mobile-container"},[_c('div',{staticClass:"title"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.mobileTitle),expression:"mobileTitle"}],staticClass:"title-pic"})]),_vm._v(" "),_c('div',{staticClass:"mobile-app"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.mobilePic),expression:"mobilePic"}],staticClass:"mobile-img"}),_vm._v(" "),_c('p',{staticClass:"mention"},[_vm._v("ebatte App")]),_vm._v(" "),_c('div',{staticClass:"downloads"},[_c('a',{attrs:{"href":"https://apps.apple.com/cn/app/ebatte/id1527045873"}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
              'https://cdn.ebatte.com/data/assets/imgs/index/download-apple.webp'
            ),expression:"\n              'https://cdn.ebatte.com/data/assets/imgs/index/download-apple.webp'\n            "}]})]),_vm._v(" "),_c('a',{attrs:{"href":"https://play.google.com/store/apps/details?id=org.ebatte"}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
              'https://cdn.ebatte.com/data/assets/imgs/index/download-android.webp'
            ),expression:"\n              'https://cdn.ebatte.com/data/assets/imgs/index/download-android.webp'\n            "}]})])])]),_vm._v(" "),_c('div',{staticClass:"desktop-app"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
          'https://cdn.ebatte.com/data/assets/imgs/index/download/desktop.webp'
        ),expression:"\n          'https://cdn.ebatte.com/data/assets/imgs/index/download/desktop.webp'\n        "}]}),_vm._v(" "),_c('p',{staticClass:"mention"},[_vm._v("ebatte PC website")])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }