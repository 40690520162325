//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import IntroIconBadge from 'assets/imgs/index/IntroIconBadge'
export default {
  name: 'P1ECommerce',
  components: { IntroIconBadge },
}
