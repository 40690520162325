import { render, staticRenderFns } from "./none.vue?vue&type=template&id=024a53d4&"
import script from "./none.vue?vue&type=script&lang=js&"
export * from "./none.vue?vue&type=script&lang=js&"
import style0 from "./none.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./none.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Cookies: require('/var/www/ebatte/offical-site/components/utils/Cookies.vue').default,Footer: require('/var/www/ebatte/offical-site/components/Footer.vue').default})
