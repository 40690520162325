import { langList } from '~/lang'

export function getLangByCode(code: string) {
  return langList.find((l) => l.code === code)
}

export function insertWithName(str: String, params: { [Key: string]: any }) {
  // params = {"%NAME%":"Mike","%AGE%":"26","%EVENT%":"20"},
  // str = 'My Name is %NAME% and my age is %AGE%.';

  return str.replace(/%\w+%/g, function (all) {
    return params[all] || all
  })
}
