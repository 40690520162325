//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'PageLoading',
  props: {
    // 0-100
    progress: { type: Number, default: 0 },
  },
  data() {
    return {
      // eslint-disable-next-line import/no-webpack-loader-syntax
      shopImg: require('!url-loader?limit=100000!assets/icons/shop.svg'),
      // eslint-disable-next-line import/no-webpack-loader-syntax
      forumImg: require('!url-loader?limit=100000!assets/icons/forum.svg'),
      // eslint-disable-next-line import/no-webpack-loader-syntax
      logoImg: require('!url-loader?limit=100000!assets/imgs/ebatte-logo.svg'),
      recycleImg:require('!url-loader?limit=100000!assets/imgs/recycle-img.svg')
    }
  },
}
