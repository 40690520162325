//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AnimationMixin from '@/mixins/AnimationMixin'
import FloatPoints1 from '@/components/index/FloatPoints1'
import FloatPoints2 from '@/components/index/FloatPoints2'
import part1Title from '~/static/animations/index/part-top/part1/title/data.json'
import part1Video from '~/static/animations/index/part-top/part1/video/data.json'
import part2Title from '~/static/animations/index/part-top/part2/title/data.json'
import part2Content from '~/static/animations/index/part-top/part2/content/data.json'
import part2Hint from '~/static/animations/index/part-top/part2/hint/data.json'

const TOTAL_ANIMATION_NUM = 5
const TOTAL_PAGE_NUM = 2

export default {
  name: 'IndexTopSection',
  components: { FloatPoints1, FloatPoints2 },
  mixins: [AnimationMixin],
  props: {
    nowPage: { type: Number, default: 1 },
  },
  data() {
    return {
      // nowPage: 1,
      openClick: false,
      showVideo: true,
      shoppingPic: require('../../assets/shop/powerbank_img.webp'),
      part1TitleAnimationOptions: {
        loop: false,
        autoplay: false,
        animationData: part1Title,
        assetsPath: 'animations/index/part-top/part1/title/images/',
      },
      part1VideoAnimationOptions: {
        loop: false,
        autoplay: false,
        animationData: part1Video,
        assetsPath: 'animations/index/part-top/part1/video/images/',
      },
      part2TitleAnimationOptions: {
        loop: false,
        autoplay: false,
        animationData: part2Title,
        assetsPath: 'animations/index/part-top/part2/title/images/',
      },
      part2ContentAnimationOptions: {
        loop: false,
        autoplay: false,
        animationData: part2Content,
        assetsPath: 'animations/index/part-top/part2/content/images/',
      },
      part2HintAnimationOptions: {
        loop: false,
        autoplay: false,
        animationData: part2Hint,
        assetsPath: 'animations/index/part-top/part2/hint/images/',
      },
    }
  },
  watch: {
    nowPage(val) {
      if (val === 1) {
        this.startPart1Animation()
      }
      if (val === 2) {
        this.startPart2Animation()
      }
    },
  },
  mounted() {
    this.$bus.$on('closeVideo', () => {
      if (this.openClick) {
        this.openClick = false
      }
    })
    this.setTotalNum(TOTAL_ANIMATION_NUM)

    const isSafariBrowser =
      /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)
    if (isSafariBrowser) {
      this.showVideo = false
    }

    this.animationLoadCallback = () => {
      if (name === 'part1Float' || name === 'part2Float') {
        // anim.setSpeed(0.01)
      }
    }
    this.animationFinishCallback = () => {
      setTimeout(() => {
        this.startPart1Animation(true)
        this.startPart2Animation(true)
      }, 1000)
    }
  },
  methods: {
    videoShow() {
      this.openClick = true
    },
    async startPart1Animation(preventClear) {
      if (!preventClear) {
        this.clearTimeout()
      }
      if (this.$refs.earth1video) {
        this.showVideo = true
        this.$refs.earth1video.currentTime = 0
        try {
          await this.$refs.earth1video.play()
        } catch (e) {
          console.log('play failed:', e)
          this.showVideo = false
        }
      } else {
        this.showVideo = false
      }
      this.animation.part1Title.goToAndPlay(0)
      this.animation.part1Video.goToAndStop(0)
      this.addTimeOut(
        setTimeout(() => this.animation.part1Video.goToAndPlay(0), 800)
      )
    },
    async startPart2Animation(preventClear) {
      if (!preventClear) {
        this.clearTimeout()
      }
      if (this.$refs.earth2video) {
        this.showVideo = true
        this.$refs.earth2video.currentTime = 0
        try {
          await this.$refs.earth2video.play()
        } catch (e) {
          console.log('play failed:', e)
          this.showVideo = false
        }
      } else {
        this.showVideo = false
      }

      this.animation.part2Title.goToAndPlay(0)
      this.animation.part2Content.goToAndStop(0)
      this.animation.part2Hint.goToAndStop(0)
      this.addTimeOut(
        setTimeout(() => this.animation.part2Content.goToAndPlay(0), 800),
        setTimeout(() => this.animation.part2Hint.goToAndPlay(0), 1200)
      )
    },
    watchVideo() {
      window.location.href = 'https://cdn.ebatte.com/video/formal.mp4'
    },
    beforePage() {
      if (this.nowPage > 1) {
        // this.nowPage -= 1
        this.$emit('before')
      }
    },
    nextPage() {
      if (this.nowPage < TOTAL_PAGE_NUM) {
        // this.nowPage += 1
        this.$emit('next')
      }
    },
  },
}
